"use strict";
// define datalayer and gtag function
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

gtag("consent", "default", {
  // prettier-ignore
  "analytics_storage":"denied",
});

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-WQ86HZL");

// accept / reject functionality

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setTime() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    let ccExpires = "expires=" + date.toUTCString();
    return ccExpires;
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}; ${ccExpires}`;
  },
};

const storageType = cookieStorage;
const consentPropertyName = "cc_consent";
const ccExpires = storageType.setTime();

const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
const saveToStorage = () => storageType.setItem(consentPropertyName, true);

window.onload = () => {
  const ccModal = document.getElementById("cc");
  const acceptBtn = document.getElementById("cc-accept");
  const rejectBtn = document.getElementById("cc-reject");
  const body = document.querySelector("body");
  const ccReject = function () {
    saveToStorage(storageType);
    ccModal.classList.add("invisible");
    gtag("consent", "update", {
      // prettier-ignore
      "analytics_storage": "denied",
    });
  };
  const ccAccept = function () {
    gtag("consent", "update", {
      // prettier-ignore
      "analytics_storage": "granted",
    });
    saveToStorage(storageType);
    ccModal.classList.add("invisible");
    window.location.reload();
  };
  const renderOptions = function () {
    const html = `<button  id="cc-options" class="absolute bottom-0 left-1 bg-slate-800/60 px-6 rounded-t-lg text-white/60 text-sm">Cookies</button>`;
    body.insertAdjacentHTML("beforeend", html);
  };
  const restartCC = function () {
    ccModal.classList.remove("invisible");
    ccModal.classList.add("opacity-100");
    ccModal.classList.remove("opacity-0");
  }; // build in functionality for clearing cookies and returning state to normal
  acceptBtn.addEventListener("click", ccAccept);
  rejectBtn.addEventListener("click", ccReject);

  if (shouldShowPopup(storageType)) {
    setTimeout(() => {
      ccModal.classList.remove("opacity-0");
      ccModal.classList.remove("invisible");
      ccModal.classList.add("opacity-100");
    }, 2000);
  }
  if (!shouldShowPopup(storageType)) {
    renderOptions();
    const optionsBtn = document.getElementById("cc-options");
    optionsBtn.addEventListener("click", restartCC);
  }
};
